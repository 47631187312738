.experiences {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .experiences .experienceTitle {
    text-align: center;
    color: #3e497a;
    font-size: 60px;
    font-weight: bolder;
  }

  
  .experienceList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Flexible columns */
    gap: 40px; /* Space between items */
    justify-content: center; /* Center grid items horizontally */
    align-content: center; /* Center grid content vertically */
    width: 100%; /* Full width for better centering */
    max-width: 1200px; /* Optional: Limit the grid's maximum width */
    margin: 0 auto; /* Horizontally center the grid container */
    padding: 20px; /* Optional: Add padding around the grid */
  }
  
  /* MENU ITEM STYLING */
  
  .experienceItem {
    border-radius: 15px;
    width: 300px;
    height: 300px;
    margin: 40px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  .experienceItem:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
    align-items: center;
  }
  
  .experienceItem .bgImage {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .experienceItem h1 {
    font-size: 25px;
  }
  
  @media only screen and (max-width: 1300px) {
    .experienceList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .experienceList {
      grid-template-columns: 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .experienceList {
      width: 100%;
    }
    .experienceItem {
      width: 300px;
      height: 300px;
    }
  }