.experience {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .experience h1 {
    margin-top: 50px;
    font-size: 50px;
  }
  
  .experience img {
    width: 700px;
    border-radius: 10px;
  }
  
  .experience p {
    padding-left: 25%;
    padding-right: 25%;
    font-size: 16px;
  }
  
  .experience svg {
    font-size: 60px;
  }
  
  @media only screen and (max-width: 600px) {
    .experience {
      height: auto;
      padding: 0px;
    }
  
    .experience h1 {
      font-size: 30px;
    }
  
    .experience img {
      align-items: center;
      width: 100%;
    }
  
    .experience p {
      font-size: 14px;
      padding-left: 5%;
      padding-right: 5%;
    }
  
    .experience svg {
      font-size: 40px;
    }
  }
  
  