.project {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.project h1 {
  margin-top: 50px;
  font-size: 50px;
}

.project img {
  width: 700px;
  border-radius: 10px;
}

.project p {
  padding-left: 25%;
  padding-right: 25%;
  font-size: 16px;
}

.project svg {
  font-size: 60px;
}

@media only screen and (max-width: 600px) {
  .project {
    height: auto;
    padding: 0px;
  }

  .project h1 {
    font-size: 30px;
  }

  .project img {
    align-items: center;
    width: 100%;
  }

  .project p {
    font-size: 14px;
    padding-left: 5%;
    padding-right: 5%;
  }

  .project svg {
    font-size: 40px;
  }
}

