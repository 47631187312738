.home {
    width: 100%;
    align-items: center;
    font-family: "Arial", sans-serif;
  }

  .portrait {
    width: 50%;
    height: auto;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background-color: #005035;
  }

  @media only screen and (max-width: 600px) {
    .portrait {
      width: 50%;
    }
  }
  
  .photo {
    background-color: #005035;
  }

  .about {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #005035;
    color: #f0f0f0;
  }
  
  .about h2 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 80px;
    color: #A49665;
    height: 50px;
  }
  
  .about .prompt {
    width: 40%;
    font-size: 30px;
  }
  .prompt svg {
    font-size: 60px;
    margin: 5px;
    color: white;
  }
  
  .skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  
  .h1 {
    font-size: 60px;
  }
  
  .skills .list {
    list-style: none;
    width: 60%;
    
  }
  
  .list span {
    font-size: 20px;
  }
  
  @media only screen and (max-width: 600px) {
    .about h2 {
      font-size: 40px;
    }
  
    .about .prompt {
      margin-top: 10px;
      font-size: 20px;
      width: 80%;
    }
  
    .skills {
      text-align: center;
    }
    .list {
      padding: 0;
    }
  
    .skills h2 {
      font-size: 30px;
    }
  }